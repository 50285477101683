<template>
  <section>
      <screenshot name-pdf="Histórico Clima"></screenshot>
      <b-row>
          <b-col>
              <repeat-statistics :data="itemsData" size="3" />
          </b-col>
      </b-row>
      <bruna-element-loader  :dataUrl="chartWind">
      </bruna-element-loader>
      <bruna-element-loader  :dataUrl="chartTemp">
      </bruna-element-loader>
      <bruna-element-loader  :dataUrl="chartHum">
      </bruna-element-loader>


      <b-row>
          <b-col>
              <ag-grid-table :configUrl="config" :dataUrl="data" ></ag-grid-table>
          </b-col>
      </b-row>
  </section>
</template>

<script>
import { BRow, BCol, VBPopover, VBTooltip } from "bootstrap-vue";
import Screenshot from "@/views/clients/pf/components/Screenshot";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import useApiServices from "@/services/useApiServices.js";
import axios from "@axios";
import RepeatStatistics from "@/views/clients/pf/components/RepeatStatistics";
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue';

export default {
  name: "Histórico Clima",
  components: {
      BRow,
      BCol,
      VBPopover,
      VBTooltip,
      Screenshot,
      VBPopover,
      VBTooltip,
      AgGridTable,
      RepeatStatistics,
      BrunaElementLoader,
  },
  data() {
      return {
          config: useApiServices.historicoClimaSapDemoConfig,
          data: useApiServices.historicoClimaSapDemoData,

          chartWind: useApiServices.chartWindSapDemo,
          chartTemp: useApiServices.chartTempSapDemo,
          chartHum: useApiServices.chartHumSapDemo,


          itemsData: [],
      };
  },
  directives: {
      "b-tooltip": VBTooltip,
      "b-popover": VBPopover,
  },
  // Repeat Statistics Cards
  mounted() {
      axios
          .get(useApiServices.historicoClimaSapDemoStatistics)
          .then((response) => {
              this.itemsData = response.data;
          })
          .catch((error) => { });
  },
};
</script>

<style lang="scss">

</style>
